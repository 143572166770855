import { Container, Divider, Typography, Button, Grid2, IconButton, Tooltip
  } from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo1 from './screenshots/logo1.jpg';
import logo2 from './screenshots/logo2.png';
import logo3 from './screenshots/logo3.png';
import ss1 from './screenshots/Screenshot1.png';
import ss2 from './screenshots/Screenshot2.png';
import ss3 from './screenshots/Screenshot3.png';
import ss4 from './screenshots/Screenshot4.png';
import ss5 from './screenshots/Screenshot5.png';
import ss6 from './screenshots/Screenshot6.png';
import ss7 from './screenshots/Screenshot7.png';
import ss8 from './screenshots/Screenshot8.png';
import ss9 from './screenshots/Screenshot9.png';
import { useState } from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CircleIcon from '@mui/icons-material/Circle';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9727',
      contrastText: 'white',
    },
  },
  typography: {
    // fontFamily: ['Montserrat', 'serif'].join(','), Uncomment if you want all the typography to inherit this font.
    button: {
      fontFamily: ['Montserrat', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
  }
});

const screenshots = [logo1, logo2, logo3];
const screenshots2 = [ss1, ss2, ss3, ss4, ss5, ss6, ss7, ss8, ss9];

const PressDead = () => {
    const [image, setImage] = useState(0);

    return <ThemeProvider theme={theme}>
        <Helmet>
            <title>Dead Spawn Press Kit - YZee Games</title>
            <meta name="description" content="All details regarding Dead Spawn. Dead Spawn is a top-down zombie shoot 'em up with a building mechanic. Dead Spawn is inspired heavily by Box Head Zombies." />
        </Helmet>
        <Container maxWidth="md">

            <Container sx={{paddingBottom:15, paddingTop:15}}>
                <Divider color='white'/>
                <Grid2 container justifyContent={'center'} alignItems={'center'}>
                    <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55, maxWidth:'md'}} color='white'>Dead Spawn Press Kit</Typography>
                </Grid2>
                <Divider color='white' sx={{marginBottom: 3}}/>
            
                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Description</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'>A crew and yourself have crash landed at an unknown location to find it infested with zombies. Players are able to upgrade their weapons as well as themselves. Loot is dropped per round, giving players weapons, projectiles, turrets, walls and much more. Every 10 waves a jet must be boarded to get to a new location. Choose a perk per round, and fight off endless waves of zombies.</Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Features</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'>
                    <ul>
                        <li>Players can build walls and turrets to defends themselves</li>
                        <li>Endless waves</li>
                        <li>Each wave will result in a choice of 3 good or bad perks that will last the whole round</li>
                        <li>Each zone is procedurally generated, with 4 total biomes</li>
                        <li>Players can upgrade their weapons, as well as themselves</li>
                        <li>Dead enemies have a chance of dropping a power-up, such as a nuke</li>
                        <li>Play with up to 4 players, local co-op</li>
                        <li>Players have the choice of 4 weapons and 4 projectiles</li>
                    </ul>
                </Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Fact Sheet</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Developer:</strong> Yzee Games; based in Melbourne, Australia</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Publisher:</strong> Yzee Games</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Release Date:</strong> September 24th</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Platforms:</strong> Windows/Steam</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Website:</strong> <a style={{color:'#ff9727'}} href="/">yzeegames.com</a></Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Price:</strong> USD $4.99</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Languages:</strong> English</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>File Size:</strong> 50MB</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Press Contact:</strong> <a style={{color:'#ff9727'}} href="mailto: yzeegames@gmail.com">yzeegames@gmail.com</a></Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>History</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Yzee Games</strong> was founded in 2016 by Lucas. YZee Games started with a simple IOS game, <strong>Boulder Surge</strong>, an arcade dodger, released on September, 2016.
                Lucas then spent a year working on game jam submissions, winning the <a href="https://itch.io/jam/weirder-stuff-2/rate/186455" style={{color:'#ff9727'}}>Weirder Stuff 2 game jam</a> by Clickteam.
                YZee Games began developing Dead Spawn around May of 2016 and is set for release on the 20th of September on Steam.
                YZee Games is made up of a few gamers from Australia as well as freelancers providing services such as art.</Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Members</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Programming/design:</strong> Lucas Hellyer</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Art:</strong> Milos Kostic</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Music:</strong> Esion Noise</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>QA:</strong> Marcos Mena</Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Screenshots</Typography>
                <Grid2 container direction={'column'} alignItems={'center'} sx={{position: 'relative'}}>
                    <img alt={"screenshots"} src={screenshots2[image]} style={{width:'100%', marginTop:15, marginBottom:10, border:'1px white solid'}}></img>
                    <Grid2 sx={{ fontSize: 25, color: 'white', position: 'absolute', bottom: '10px'}}>
                        <Tooltip title="Previous"><IconButton onClick={() => {setImage(image === 0 ? screenshots.length - 1 : image - 1)}} sx={{ "&:hover": {color: "#ff9727"}, color:'white'}}><ArrowLeftIcon sx={{fontSize:35}}/></IconButton></Tooltip>
                        {screenshots2.map((_, index) => {
                            return <IconButton sx={{color: index === image ? "#ff9727" : 'white'}} onClick={() => {setImage(index)}}><CircleIcon sx={{fontSize:'small'}}/></IconButton>
                        })}
                        <Tooltip title="Next"><IconButton onClick={() => {setImage((image + 1) % screenshots.length)}} sx={{ "&:hover": {color: "#ff9727"}, color:'white'}}><ArrowRightIcon sx={{fontSize:35}}/></IconButton></Tooltip>
                    </Grid2>
                </Grid2>
                <Button sx={{marginTop:3, width:300, fontSize: 20, color:'#463c30'}} href={'/Dead_Spawn_Screenshots.zip'} download variant="contained">Download</Button> 
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Logos</Typography>
                <Grid2 container direction={'column'} alignItems={'center'}>
                    <Grid2 container spacing={3} columns={{xs:12, sm:12, lg: 12}} >
                        {screenshots.map((i) => {
                            return <Grid2 size={{xs: 12, sm:6, md:4}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                                <img alt={"screenshot1"} src={i} style={{width:'100%'}}></img>
                            </Grid2>
                        })}
                    </Grid2>
                </Grid2>
                <Button sx={{marginTop:3, width:300, fontSize: 20, color:'#463c30'}} href={'/Dead_Spawn_Logos.zip'} download variant="contained">Download</Button> 
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Contact</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Twitter:</strong> <a style={{color:'#ff9727'}} href={"https://twitter.com/YZeeGames"}>https://twitter.com/YZeeGames</a></Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>YouTube:</strong> <a style={{color:'#ff9727'}} href={"https://www.youtube.com/c/YZeeGames"}>https://www.youtube.com/c/YZeeGames</a></Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Email:</strong> <a style={{color:'#ff9727'}} href={"https://www.yzeegames.com/contact/"}>https://www.yzeegames.com/contact/</a></Typography>
                
            </Container>
        </Container>

  </ThemeProvider>
}

export default PressDead;