import { Container, Divider, Typography, Button, Grid2  } from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ps1 from './screenshots/ps1.png';
import ps2 from './screenshots/ps2.png';
import ps3 from './screenshots/ps3.png';
import ps4 from './screenshots/ps4.png';
import ps5 from './screenshots/ps5.png';
import ps6 from './screenshots/ps6.png';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9727',
      contrastText: 'white',
    },
  },
  typography: {
    // fontFamily: ['Montserrat', 'serif'].join(','), Uncomment if you want all the typography to inherit this font.
    button: {
      fontFamily: ['Montserrat', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
  }
});

const screenshots = [ps1, ps2, ps3, ps4, ps5, ps6]

const Sprint = () => {
    return <ThemeProvider theme={theme}>
        <Helmet>
            <title>Pixel Sprint - YZee Games</title>
            <meta name="description" content="Pixel Sprint is a game about trade-offs; giving up life is the only way to defeat enemies. Pixel Sprint was made during a game-jam." />
        </Helmet>
        <Container maxWidth="md">

            <Container sx={{paddingBottom:15, paddingTop:15}}>
                <Divider color='white'/>
                <Grid2 container justifyContent={'center'} alignItems={'center'}>
                    <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55, maxWidth:'md'}} color='white'>Pixel Sprint</Typography>
                </Grid2>
                <Divider color='white' sx={{marginBottom: 3}}/>
            
                <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 3}} color='white'>You are in a neon world with no oxygen and are surrounded by enemies. You have a certain amount of power you must sustain. Unfortunately, the only way to defeat enemies is to sprint which uses power. When your power drops below zero you will lose your shield and begin the loose health slowly.</Typography>
                
                <Grid2 container justifyContent={'center'} alignItems={'center'}>
                    <Button sx={{marginTop:3, width:200, fontSize: 20, color:'#463c30'}} href={'https://www.newgrounds.com/portal/view/696485'} variant="contained">PLAY!</Button> 
                </Grid2>
                
            </Container>
        </Container>

    <Container sx={{backgroundColor:'white', minWidth:'100%', paddingBottom:25, paddingTop:15}}>
        <Container maxWidth="md">
            <Grid2 container direction={'column'} alignItems={'center'}>
                <Grid2 container>
                <Typography fontFamily={'Bahiana'} sx={{fontSize: 46.5}} color='#463c30'>Screenshots</Typography>
                </Grid2>

                <Grid2 container spacing={3} columns={{xs:12, sm:12, lg: 12}} >
                    {screenshots.map((i) => {
                        return <Grid2 size={{xs: 12, sm:12, md:6}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                            <img alt={"screenshot1"} src={i} style={{width:'100%'}}></img>
                        </Grid2>
                    })}
                </Grid2>
            </Grid2>
        </Container>
    </Container>

  </ThemeProvider>
}

export default Sprint;