import { Container, Divider, Typography, Button, Grid2  } from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo1 from './screenshots/logo1.jpg';
import logo2 from './screenshots/logo2.png';
import logo3 from './screenshots/logo3.png';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9727',
      contrastText: 'white',
    },
  },
  typography: {
    button: {
      fontFamily: ['Montserrat', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
  }
});

const screenshots = [logo1, logo2, logo3]

const Press = () => {
    return <ThemeProvider theme={theme}>
        <Helmet>
            <title>Press Kit - YZee Games (Fact Sheet, Members, etc)</title>
            <meta name="description" content="All details for press regarding information about YZee Games; fact sheet, members, games, logos, etc. Individual game press kits can also be found here." />
        </Helmet>
        <Container maxWidth="md">

            <Container sx={{paddingBottom:15, paddingTop:15}}>
                <Divider color='white'/>
                <Grid2 container justifyContent={'center'} alignItems={'center'}>
                    <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55, maxWidth:'md'}} color='white'>YZeeGames Press Kit</Typography>
                </Grid2>
                <Divider color='white' sx={{marginBottom: 3}}/>
            
                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Fact Sheet</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Developer:</strong> Yzee Games; based in Melbourne, Australia</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Website:</strong> <a style={{color:'#ff9727'}} href="/">yzeegames.com</a></Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Press Contact:</strong> <a style={{color:'#ff9727'}} href="mailto: yzeegames@gmail.com">yzeegames@gmail.com</a></Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>History</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Yzee Games</strong> was founded in 2016 by Lucas. YZee Games started with a simple IOS game, <strong>Boulder Surge</strong>, an arcade dodger, released on September, 2016.
                Lucas then spent a year working on game jam submissions, winning the <a href="https://itch.io/jam/weirder-stuff-2/rate/186455" style={{color:'#ff9727'}}>Weirder Stuff 2 game jam</a> by Clickteam.
                YZee Games began developing Dead Spawn around May of 2016 and is set for release on the 20th of September on Steam.
                YZee Games is made up of a few gamers from Australia as well as freelancers providing services such as art.</Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Members</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Programming/design:</strong> Lucas Hellyer</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Art:</strong> Milos Kostic</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Music:</strong> Esion Noise</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>QA:</strong> Marcos Mena</Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Game Press Kits</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong><a style={{color:'#ff9727'}} href="/dead-spawn-press-kit">Dead Spawn</a></strong></Typography>
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Logos</Typography>
                <Grid2 container direction={'column'} alignItems={'center'}>
                    <Grid2 container spacing={3} columns={{xs:12, sm:12, lg: 12}} >
                        {screenshots.map((i) => {
                            return <Grid2 size={{xs: 12, sm:6, md:4}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                                <img alt={"screenshot1"} src={i} style={{width:'100%'}}></img>
                            </Grid2>
                        })}
                    </Grid2>
                </Grid2>
                <Button sx={{marginTop:3, width:300, fontSize: 20, color:'#463c30'}} href={'/Dead_Spawn_Logos.zip'} download variant="contained">Download</Button> 
                <Divider color="white" sx={{marginTop: 3, marginBottom:3}}></Divider>

                <Typography fontFamily={'Bahiana'} sx={{fontSize: 35}} color='white'>Contact</Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Twitter:</strong> <a style={{color:'#ff9727'}} href={"https://twitter.com/YZeeGames"}>https://twitter.com/YZeeGames</a></Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>YouTube:</strong> <a style={{color:'#ff9727'}} href={"https://www.youtube.com/c/YZeeGames"}>https://www.youtube.com/c/YZeeGames</a></Typography>
                <Typography fontFamily={'Bai Jamjuree'} sx={{fontSize: 18}} color='white'><strong>Email:</strong> <a style={{color:'#ff9727'}} href={"https://www.yzeegames.com/contact/"}>https://www.yzeegames.com/contact/</a></Typography>
                
            </Container>
        </Container>

  </ThemeProvider>
}

export default Press;