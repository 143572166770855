import { Container, Divider, Typography, Button, Grid2 } from '@mui/material/';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import gb1 from './screenshots/gb1.png';
import gb2 from './screenshots/gb2.png';
import gb3 from './screenshots/gb3.png';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9727',
      contrastText: 'white',
    },
  },
  typography: {
    // fontFamily: ['Montserrat', 'serif'].join(','), Uncomment if you want all the typography to inherit this font.
    button: {
      fontFamily: ['Montserrat', 'serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
  }
});

const screenshots = [gb1, gb2, gb3]

const Galactic = () => {
    return <ThemeProvider theme={theme}>
        <Helmet>
            <title>Galactic Break - YZee Games</title>
            <meta name="description" content="Galactic Break is a space game in which the player must transverse difficult obstacles by skillfully and carefully driving their ship." />
        </Helmet>
        <Container maxWidth="md">

            <Container sx={{paddingBottom:15, paddingTop:15}}>
                <Divider color='white'/>
                <Grid2 container justifyContent={'center'} alignItems={'center'}>
                    <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55, maxWidth:'md'}} color='white'>Galactic Break</Typography>
                </Grid2>
                <Divider color='white' sx={{marginBottom: 3}}/>
            
                <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 3}} color='white'>Galactic Break is a space game in which the player must transverse difficult obstacles by skillfully and carefully driving their ship. The majority of the map is black therefore the player must use their light to become aware of the environment.</Typography>
                
                <Grid2 container justifyContent={'center'} alignItems={'center'}>
                    <Button sx={{marginTop:3, width:200, fontSize: 20, color:'#463c30'}} href={'https://yzeegames.itch.io/galactic-break'} variant="contained">PLAY!</Button> 
                </Grid2>
                
            </Container>
        </Container>

    <Container sx={{backgroundColor:'white', minWidth:'100%', paddingBottom:25, paddingTop:15}}>
        <Container maxWidth="md">
            <Grid2 container direction={'column'} alignItems={'center'}>
                <Grid2 container>
                <Typography fontFamily={'Bahiana'} sx={{fontSize: 46.5}} color='#463c30'>Screenshots</Typography>
                </Grid2>

                <Grid2 container spacing={3} columns={{xs:12, sm:12, lg: 12}} >
                    {screenshots.map((i) => {
                        return <Grid2 size={{xs: 12, sm:12, md:6}} justifyItems={'center'} justifyContent={'center'} alignItems={'center'}>
                            <img alt={"screenshot1"} src={i} style={{width:'100%'}}></img>
                        </Grid2>
                    })}
                </Grid2>
            </Grid2>
        </Container>
    </Container>

  </ThemeProvider>
}

export default Galactic;