import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import Menu from './Menu';
import Contact from './Contact';
import Footer from './Footer';
import Surge from './Surge';
import Galactic from './Galactic';
import Sprint from './Sprint';
import Expulsion from './Expulsion';
import Press from './Press';
import PressDead from './PressDead';
import Error404 from './Error404';

const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.style = 'background: #463c30';

const validPage = window.location.pathname === "/contact/" || window.location.pathname === "/boulder-surge" 
  || window.location.pathname === "/press-kit/" || window.location.pathname === "/dead-spawn-press-kit" || window.location.pathname === "/" 
  || window.location.pathname === "/expulsion" || window.location.pathname === "/pixel-sprint" || window.location.pathname === "/galactic-break";

root.render(
  <BrowserRouter>
    <Menu/>
    <Routes>
      <Route path="/" exact element={<Home/>}/>
      <Route path="/contact" exact element={<Contact/>}/>
      <Route path="/boulder-surge/" exact element={<Surge/>}/>
      <Route path="/galactic-break/" exact element={<Galactic/>}/>
      <Route path="/pixel-sprint/" exact element={<Sprint/>}/>
      <Route path="/expulsion/" exact element={<Expulsion/>}/>
      <Route path="/press-kit/" exact element={<Press/>}/>
      <Route path="/dead-spawn-press-kit/" exact element={<PressDead/>}/>
      <Route path ="/*" element={<Error404/>} />
    </Routes>
    {validPage && <Footer/>}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
