import { Grid2, Link, Typography } from '@mui/material/';

const Footer = () => {
    return <Grid2 container sx={{backgroundColor:'#463c30', paddingTop:3, paddingBottom:3}} direction={'column'} alignItems={'center'} marginBottom={50}>
        <Typography fontFamily={'Bahiana'} sx={{fontSize:24}} color='white'><strong>YZeeGames</strong> © 2016. All Rights Reserved</Typography>
        <Grid2 container sx={{mt:1.5}} direction={'row'}>
          <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', fontSize:24}} href='/'>Home</Link>
          <Typography fontFamily={'Bahiana'} sx={{fontSize:24, mr:1, ml: 1}} color='white'>|</Typography>
          <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', fontSize:24}} href='/press-kit'>Press Kit</Link>
          <Typography fontFamily={'Bahiana'} sx={{fontSize:24, mr:1, ml: 1}} color='white'>|</Typography>
          <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', fontSize:24}} href='/contact'>Contact</Link>
        </Grid2>
    </Grid2>  
}

export default Footer;