import { IconButton, Grid2, Tooltip, Link, useMediaQuery} from '@mui/material/';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Logo from './yzeegameslogo.png';
import './Bahiana.css';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';

const styles = {
    container: {
      position: 'relative', // This allows the absolute div to be positioned relative to this container

    },
    fullWidthDiv: {
      position: 'absolute',
      top: 0, // Position it at the top
      left: 0, // Align to the left
      width: '100%', // Full width
      paddingBottom: 15,
      backgroundColor: 'white', // White background
      zIndex: 1, // Ensure it appears above other elements if necessary
    },
  };

const Menu = () => {
    const isMob = useMediaQuery('(max-width:899px)');

    const [open, setOpen] = useState(false);

    const handleClick = (_) => {
        setOpen(!open);
    };

    return isMob ? <div>
    
        <Grid2 container sx={{pt:1, pb:1}} alignItems={'center'} justifyContent={'space-between'} backgroundColor={'white'}>
            <Grid2 size={6} item container alignItems={'center'} justifyContent={'flex-start'}>
                <a href='/'><img alt={"logo"} src={Logo} style={{marginLeft:20, height:60}}></img></a>
            </Grid2>
            <Grid2 size={6} item container alignItems={'center'} justifyContent={'flex-end'}>
                <IconButton aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} style={{marginRight:20}} onClick={handleClick}><MenuIcon sx={{fontSize:40, color:'#463c30'}}/></IconButton>
            </Grid2>
        </Grid2>

        {open && <div style={styles.container}>
            <div style={styles.fullWidthDiv}>
                <Grid2 container direction={'row'} justifyContent={'flex-end'}>
                    <Tooltip title="Twitter"><IconButton href="https://x.com/YZeeGames" sx={{ transition: '0.2s ease', "&:hover": {color: "white", backgroundColor:'#ff9727'}, color:'#463c30'}}><TwitterIcon sx={{fontSize:30}}/></IconButton></Tooltip>
                    <Tooltip title="YouTube"><IconButton href="https://www.youtube.com/c/YZeeGames" sx={{ marginRight: 3, transition: '0.2s ease', "&:hover": {color: "white", backgroundColor:'#ff9727'}, color:'#463c30'}}><YouTubeIcon sx={{fontSize:30}}/></IconButton></Tooltip>
                </Grid2>
                <Grid2 container direction={'column'} alignContent={'flex-end'}>
                    <Link align='end' fontFamily={'Bahiana'} sx={{marginRight: 3, transition: '0.2s ease', textDecoration: 'none', color:'#463c30', "&:hover": {color: "white", backgroundColor:'#ff9727'}, fontSize:40.5, paddingRight:1, paddingLeft:1}} href='/'>Games</Link>
                </Grid2>
                <Grid2 container direction={'column'} alignContent={'flex-end'}>
                    <Link align='end' fontFamily={'Bahiana'} sx={{marginRight: 3, transition: '0.2s ease',textDecoration: 'none', color:'#463c30', "&:hover": {color: "white", backgroundColor:'#ff9727'}, fontSize:40.5, paddingRight:1, paddingLeft:1}} href='https://deadspawn.com/'>Dead Spawn</Link>
                </Grid2>
                <Grid2 container direction={'column'} alignContent={'flex-end'}>
                <   Link align='end' fontFamily={'Bahiana'} sx={{marginRight: 3, transition: '0.2s ease',textDecoration: 'none', color:'#463c30', "&:hover": {color: "white", backgroundColor:'#ff9727'}, fontSize:40.5, paddingRight:1, paddingLeft:1}} href='/press-kit/'>Press Kit</Link>
                </Grid2>
                <Grid2 container direction={'column'} alignContent={'flex-end'}>
                    <Link align='end' fontFamily={'Bahiana'} sx={{marginRight: 3, transition: '0.2s ease',textDecoration: 'none', color:'#463c30', "&:hover": {color: "white", backgroundColor:'#ff9727'}, fontSize:40.5, paddingRight:1, paddingLeft:1}} href='/contact/'>Contact</Link>
                </Grid2>   
            </div>
        </div>}

    </div>
:
<Grid2 container sx={{pt:2, pb:2}} alignItems={'center'} justifyContent={'space-between'} backgroundColor={'white'}> 
    <Grid2 size={12} item container alignItems={'center'} justifyContent={'center'}>

        <a href='/'><img alt={"logo"} src={Logo} style={{marginRight:20, height:60}}></img></a>

        <Link fontFamily={'Bahiana'} sx={{transition: '0.2s ease', textDecoration: 'none', color:'#463c30', "&:hover": {color: "white", backgroundColor:'#ff9727'}, mr:2, fontSize:46.5, paddingRight:1, paddingLeft:1}} href='/'>Games</Link>
        <Link fontFamily={'Bahiana'} sx={{transition: '0.2s ease',textDecoration: 'none', color:'#463c30', "&:hover": {color: "white", backgroundColor:'#ff9727'}, mr:2, fontSize:46.5, paddingRight:1, paddingLeft:1}} href='https://deadspawn.com/'>Dead Spawn</Link>
        <Link fontFamily={'Bahiana'} sx={{transition: '0.2s ease',textDecoration: 'none', color:'#463c30', "&:hover": {color: "white", backgroundColor:'#ff9727'}, mr:2, fontSize:46.5, paddingRight:1, paddingLeft:1}} href='/press-kit/'>Press Kit</Link>
        <Link fontFamily={'Bahiana'} sx={{transition: '0.2s ease',textDecoration: 'none', color:'#463c30', "&:hover": {color: "white", backgroundColor:'#ff9727'}, mr:2, fontSize:46.5, paddingRight:1, paddingLeft:1}} href='/contact/'>Contact</Link>
        <Tooltip title="Twitter"><IconButton href="https://x.com/YZeeGames" sx={{ transition: '0.2s ease', mr: 0.2, "&:hover": {color: "white", backgroundColor:'#ff9727'}, color:'#463c30'}}><TwitterIcon sx={{fontSize:35}}/></IconButton></Tooltip>
        <Tooltip title="YouTube"><IconButton href="https://www.youtube.com/c/YZeeGames" sx={{ transition: '0.2s ease', "&:hover": {color: "white", backgroundColor:'#ff9727'}, color:'#463c30'}}><YouTubeIcon sx={{fontSize:35}}/></IconButton></Tooltip>

    </Grid2>
</Grid2>
}

export default Menu;