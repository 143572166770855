import { Typography, Container, Grid2, Link } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const Error404 = () => {
    return (
        <>
        <Container component="main" maxWidth="sm" align='center' sx={{mb:25, mt:25}}>
            <Typography sx={{fontSize:80, lineHeight:1}} fontFamily={'Bahiana'} color={"white"}>ERROR 404</Typography>
            <Typography sx={{fontSize:40}} fontFamily={'Bahiana'} color={"white"}>I'm afraid this link does not exist !</Typography>
            <SmartToyIcon sx={{ fontSize:80, color:'white' }}/>
        </Container>
        
        <Container
                maxWidth={false}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: '#463c30',
                    paddingTop: 3,
                    paddingBottom: 3,
                }}
            >       
             <Grid2 container direction={'column'} alignItems={'center'}>
                    <Typography fontFamily={'Bahiana'} sx={{fontSize:24}} color='white'><strong>YZeeGames</strong> © 2016. All Rights Reserved</Typography>
                    <Grid2 container sx={{mt:1.5}} direction={'row'}>
                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', fontSize:24}} href='/'>Home</Link>
                    <Typography fontFamily={'Bahiana'} sx={{fontSize:24, mr:1, ml: 1}} color='white'>|</Typography>
                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', fontSize:24}} href='/press-kit'>Press Kit</Link>
                    <Typography fontFamily={'Bahiana'} sx={{fontSize:24, mr:1, ml: 1}} color='white'>|</Typography>
                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', fontSize:24}} href='/contact'>Contact</Link>
                    </Grid2>
                </Grid2>  
            </Container>
        </>
    )
}

export default Error404;