import { Container, Divider, Typography, Button, Grid2, TextField  } from '@mui/material/';
import emailjs from 'emailjs-com';
import { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9727',
      contrastText: 'white',
    },
  },
});

const loadRecaptcha = () => {
  const script = document.createElement('script');
  script.src = 'https://www.google.com/recaptcha/api.js';
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

const Contact = () => {
    useEffect(() => {
      loadRecaptcha();
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();

    const recaptchaResponse = document.querySelector('.g-recaptcha-response').value;

    // Verify the response
    if (recaptchaResponse) {
      console.log(recaptchaResponse)
      // Perform the form submission
      const email = event.target[0].value;
      const name = event.target[2].value;
      const subject = event.target[4].value;
      const message = event.target[6].value

      const templateParams = {
        from: name,
        reply_to: email,
        subject: subject,
        message: message,
        'g-recaptcha-response': recaptchaResponse,
      }
      
      emailjs.send('service_dhv6ne9', 'template_emucsw6', templateParams, '7jsQMCAvCUNT4G6lc')
      .then((result) => {
          alert('Message sent!');
          window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
      });
    } else {
      alert('Please complete the CAPTCHA');
    }

  }

  return <ThemeProvider theme={theme}>
    <Helmet>
        <title>Contact - YZee Games</title>
        <meta name="description" content="Contact YZeeGames via email for any inquiries; bugs, glitches, suggestions, etc." />
    </Helmet>
    <Container maxWidth="md">

        <Container sx={{paddingBottom:25, paddingTop:15}}>
          <Container>

          <Divider color='white'/>
          <Grid2 container justifyContent={'center'} alignItems={'center'}>
            <Typography align='center' fontFamily={'Bahiana'} sx={{fontSize: 55}} color='white'>CONTACT</Typography>
          </Grid2>
          <Divider color='white'/>

          <form onSubmit={handleSubmit} method="post">
            <Typography type='email' align='left' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 4}} color='white'>Email *</Typography>
            <TextField sx={{'& .MuiInputBase-root': {backgroundColor: 'white', color: '#463c30', }, input: {  color: '#463c30'}}} required fullWidth/>

            <Typography align='left' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 3}} color='white'>Name *</Typography>
            <TextField sx={{'& .MuiInputBase-root': {backgroundColor: 'white', color: '#463c30', }, input: {  color: '#463c30'}}} required fullWidth/>

            <Typography align='left' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 3}} color='white'>Subject *</Typography>
            <TextField sx={{'& .MuiInputBase-root': {backgroundColor: 'white', color: '#463c30', }, input: {  color: '#463c30'}}} required fullWidth/>

            <Typography align='left' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 3}} color='white'>Message *</Typography>
            <TextField multiline minRows={6} sx={{'& .MuiInputBase-root': {backgroundColor: 'white', color: '#463c30'}, input: {  color: '#463c30',},}} required fullWidth/>

            <Typography align='left' fontFamily={'Bahiana'} sx={{fontSize: 26, marginTop: 3}} color='white'>reCAPTCHA *</Typography>
            <div className="g-recaptcha" data-sitekey="6Led5l8qAAAAAL19MSGELuFtGtPyv4UgyO-PFq7_"></div>
            <Button fullWidth sx={{marginTop: 6, fontSize: 26, color: '#463c30'}} type='submit' variant="contained">SEND</Button> 
          </form>
            
          </Container>
        </Container>
  
  </Container>
  </ThemeProvider>
}

export default Contact;